/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@200;400;600;700;900&family=Space+Mono:wght@400;700&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.css";
@import "~angular-notifier/styles";
@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";

/* @font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Regular.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Regular.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Bold.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
} */

/* @font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Medium.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Medium.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Bold.woff2") format("woff2"),
    url("./assets/fonts/Raleway-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
} */

body,
input {
  font-family: "Poppins" !important;
  font-size: 14px !important;
  background-color: transparent !important;
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins" !important;
}

/* html {
  font-family: "Raleway", sans-serif !important;
} */
body {
  margin: 0px;
  background-color: #edf3ff !important;
  overflow: hidden;
}

/* width */
/* body::-webkit-scrollbar {
  width: 10px;
} */

.mat-drawer-inner-container::-webkit-scrollbar,
.mat-dialog-content::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.mat-drawer-inner-container::-webkit-scrollbar-track,
.mat-dialog-content::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.mat-drawer-inner-container::-webkit-scrollbar-thumb,
.mat-dialog-content::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #747f88;
  border-radius: 10px;
}

/* Handle on hover */
.mat-drawer-inner-container::-webkit-scrollbar-thumb:hover,
.mat-dialog-content::-webkit-scrollbar-thumb:hover,
body::-webkit-scrollbar-thumb:hover {
  background: #747f88;
}

.mat-drawer-content {
  /* margin-left: 264px;
    margin-top: 82px;
    margin-right: 16px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px -1px rgba(69,90,100,.3); */
  /* padding-top: 10px; */
  overflow: unset !important;
}

.ng-star-inserted #contentToConvert {
  background: white;
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3); */
  /* padding: 24px 32px; */
  margin-top: 70px;
}

.tax-table {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 12px;
}

/* .tax-table-body td {
  text-align: right;
} */
.tax-tbl-hdr td {
  font-weight: bold;
}

.tax-div {
  overflow-x: scroll;
  overflow-y: hidden;
}

table {
  width: 100%;
}

.mat-elevation-z8 {
  padding: 10px !important;
}

.form-input-number {
  text-align: right !important;
}

.form-input-number::-webkit-inner-spin-button,
.form-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contract-form {
  min-width: 150px;
  width: 100%;
}

.contract-full-width {
  width: 100%;
}

.form-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
}

.form-margin {
  margin: 0 10px;
}

.form-button {
  text-align: right;
  margin: 10px !important;
}

.contract-item-table tr {
  line-height: 40px;
}

.action-column {
  width: 150px;
  text-align: right;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.form-fieldset {
  border-color: lightgray !important;
}

.form-fieldset legend {
  color: #3f51b5 !important;
}

.color-h3 {
  color: rgba(44, 44, 44, 0.87) !important;
  padding: 5px;
  /* text-align: center; */
  font-weight: 600;
  font-size: 18px;
  /* box-shadow: 0 5px 5px 0px rgba(69, 90, 100, 0.3); */
  line-height: 30px;
}

.table-details {
  padding: 25px 25px;
}

.mat-list-item-content:hover {
  margin-left: 7px;
  transition: 0.5s;
  background: transparent;
}

.active-list-item {
  color: #4680ff !important;
  margin-left: 7px;
}

.mat-column-actions {
  text-align: right;
  width: 150px;
}

.mat-header-cell {
  color: #3f51b5;
  font-size: 15px;
}

legend {
  font-size: 13px !important;
}

.form-bold-label {
  font-weight: bold !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
  margin: 0px 5px;
  color: black;
}

.table-font-arial {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  color: black;
}

input.mat-input-element {
  outline: 0 !important;
  color: black !important;
}

.big {
  font-size: 20px;
}

.right {
  text-align: right !important;
}

.fill-remaining-space {
  width: 64%;
}

input[type="number"] {
  /* font-family: Arial, Helvetica, sans-serif !important; */
  text-align: right;
}

input[readonly="true"] {
  background-color: #e9ecef !important;
  padding: 4px;
  opacity: 1;
  margin-bottom: -5px;
}

.mat-form-field-label {
  color: black !important;
}

/* .mat-form-field-underline {
background-color: black !important;
}  */
.table-column-width {
  width: 150px;
}

.amount-table th {
  text-align: right;
  line-height: 38px;
  width: 110px;
}

.net-amt-table td {
  text-align: right;
}

.round-sign {
  text-align: right;
  width: 100px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

.mat-menu-panel {
  max-height: 400px !important;
  overflow-x: hidden !important;
}

.mat-menu-content {
  overflow-x: hidden !important;
}

.close-icon {
  margin-left: auto;
  order: 2;
  font-weight: bolder;
}

.close-icon:hover {
  cursor: pointer;
}

.mat-dialog-actions {
  flex-wrap: inherit !important;
}

.not-sec .mat-card-header-text {
  margin: 0 5px !important;
}

.not-sec .mat-list-item-content {
  font-size: 12px !important;
}

.kot-sec .mat-list-item-content {
  font-size: 12px !important;
}

/* .mat-dialog-actions{
  box-shadow: 0 -5px 5px -5px #333;
}
.mat-dialog-title{
  box-shadow: 0 5px 5px -5px #333;
} */
.mat-list-item mat-icon {
  font-size: 16px !important;
  width: auto !important;
  height: auto !important;
}

.mat-select {
  display: contents !important;
}

/* autocomplete css */
.item-font {
  font-size: 13px;
}

.item-rate-font {
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
}

.item-subtitle {
  font-weight: bold;
  font-size: 10px;
}

.item-rate {
  font-size: 10px;
}

.mat-option {
  height: 50px !important;
  line-height: 20px !important;
}

.mat-option.mat-active {
  background-color: #3f51b5 !important;
  color: white !important;
}

.ob-cb {
  background-color: #3f51b5 !important;
  color: white !important;
}

.red {
  color: red;
}

.center {
  text-align: center;
}

.danger {
  color: red;
  font-size: 12px;
}

.mat-mini-fab.mat-accent {
  background-color: #3f51b5 !important;
}

.row.width {
  width: 100%;
  text-align: center;
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
}

.mat-dialog-title {
  margin: 0 !important;
}

.btn-primary {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 5px 2px rgba(69, 90, 100, 0.3) !important;
}

button.btn {
  box-shadow: 0px 1px 5px 2px rgba(69, 90, 100, 0.3) !important;
  color: #005ad9 !important;
  font-weight: 500 !important;
  background: white !important;
}

button.btn:hover {
  color: white !important;
  background-color: #747f88 !important;
}

@media screen and (max-width: 768px) {
  .action-column {
    width: auto;
  }

  .round-sign {
    width: auto;
    padding-left: 10px;
  }

  .mat-radio-button {
    width: auto !important;
  }

  .fill-remaining-space {
    width: 0%;
  }

  .amount-table td {
    width: 70%;
  }

  .big {
    font-size: 16px;
  }

  .mat-card {
    margin-top: 10px;
  }

  /* Force table to not be like tables anymore */
  .contract-item-table table,
  .contract-item-table thead,
  .contract-item-table tbody,
  .contract-item-table th,
  .contract-item-table td,
  .contract-item-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .contract-item-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .contract-item-table thead th {
    text-align: left;
  }

  .contract-item-table thead {
    display: none;
  }

  .contract-item-table tr {
    border-bottom: 5px solid rgb(189, 186, 186);
    margin-bottom: 4px !important;
  }

  .contract-item-table td {
    /* Behave like a "row" */
    border: none !important;
    padding: 0 !important;
    position: relative;
    white-space: normal;
    text-align: right;
    font-weight: bold;
  }

  .contract-item-table td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0px;
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    color: #3f51b5;
  }

  /*
       Label the data
       */
  .contract-item-table td:before {
    content: attr(data-title);
  }

  .mat-footer-cell,
  .mat-footer-row {
    display: none;
  }

  .mat-table {
    border: 0;
    vertical-align: middle;
    padding: 20px !important;
  }

  .mat-table caption {
    font-size: 1em;
  }

  /* Enable this to hide header */
  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  tr.mat-header-row {
    height: 0px !important;
  }

  .mat-table .mat-row {
    border-bottom: 5px solid rgb(190, 189, 189);
  }

  /* .mat-table .mat-row:nth-child(even) {
    padding: 5px;
    background: rgb(235, 233, 233)}
  .mat-table .mat-row:nth-child(odd) {
    padding: 5px;
    background: #FFF} */

  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    height: 30px;
    margin-bottom: 4%;
  }

  .mat-table .mat-cell:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    color: #3f51b5;
    font-size: 0.85em;
  }

  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }

  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }

  .mat-column-actions {
    width: auto;
  }

  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type,
  th.mat-header-cell:last-of-type {
    padding-right: 0px !important;
  }

  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type,
  th.mat-header-cell:first-of-type {
    padding-left: 0px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}

.mat-expansion-indicator::after {
  border-width: 0 1.4px 1.4px 0 !important;
}

mat-sidenav-content.mat-drawer-content.mat-sidenav-content {
  /* background: #4680ff; */
  background-image: linear-gradient(rgba(255, 255, 255, 0.3) 200px,
      rgba(255, 255, 255, 0.9) 0);
}

.panelcard {
  /* background: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3); */
  /* padding: 24px 32px; */
  margin-top: 70px;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #4680ff;
}

.mat-raised-button {
  min-width: 0 !important;
}

.col-md-3:nth-child(1) .ob-cb {
  background: #9ccc65 !important;
}

.col-md-3:nth-child(2) .ob-cb {
  background: #ffba57 !important;
}

.col-md-3:nth-child(3) .ob-cb {
  background: #ff5252 !important;
}

.col-md-3:nth-child(4) .ob-cb {
  background: #448aff !important;
}

.col-md-3 .ob-cb {
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3);
}

.d-card .mat-card-title {
  text-align: right;
  font-size: 26px;
  font-weight: bold;
}

.d-card .mat-card-subtitle {
  text-align: right;
  font-weight: bold;
}

.d-card {
  box-shadow: 0 2px 10px -1px rgba(69, 90, 100, 0.3) !important;
  transition: 0.2s;
  padding: 22px 18px 22px 0px !important;
  overflow: hidden;
}

.content-scroll {
  overflow-y: scroll;
  height: 90vh;
  overflow-x: hidden;
}


.c-invoice {
  color: #9ccc65;
  border-bottom: 5px solid;
}

.c-purchase {
  color: #ffba57;
  border-bottom: 5px solid;
}

.c-do {
  color: #448aff;
  border-bottom: 5px solid;
}

.c-so {
  color: #ff5252;
  border-bottom: 5px solid;
}

i.material-icons.wtico {
  position: absolute;
  font-size: 6rem;
  color: #98989847;
  top: 0px;

  transition: 0.2s;
}

.d-card:hover .wtico {
  font-size: 7rem;
}

.mat-list-item-content {
  padding: 0 0 !important;
}

tr.mat-row {
  height: 33px !important;
}

.mat-cell,
.mat-footer-cell {
  font-size: 13px;
}

.small-screen-view {
  display: none;
}

@media screen and (max-width: 768px) {

  .tab-details.content-scroll,
  .bill-details.content-scroll {
    overflow: hidden;
    height: 100%;
  }

  .big-screen-view,
  .inner-side-left {
    display: none;
  }

  .small-screen-view {
    display: block;
  }

  .color-h3 {
    font-size: 20px;
  }

  span.mat-button-wrapper {
    margin-right: 10px;
  }

  .panelcard table {
    padding: 10px 6px;
  }

  .mfullwidth {
    width: 100%;
    margin-bottom: 3px;
  }

  .mfullwidth1 {
    width: 100% !important;
    margin-bottom: 5px !important;
  }

  .closeMargin {
    margin-left: 8px !important;
  }

  .mat-dialog-container {
    max-width: 96vw !important;
    min-width: 50vw !important;
    margin: 0px auto;
  }

  .cdk-overlay-container {
    left: -30px !important;
    margin: auto;
  }

  .mat-form-field-infix {
    width: 0px !important;
    min-width: 70px !important;
  }
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

i.fa.fa-plus {
  padding-right: 5px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #005ad9 !important;
}

.slide-tab .mat-tab-label:hover {
  color: white !important;
  background-color: blue !important;
}

button.mat-button:hover {
  background: #005ad9 !important;
  color: white !important;
  width: 95% !important;
  text-align: left !important;
}

button.mat-button:active {
  background: #005ad9 !important;
  color: white !important;
  width: 95% !important;
  text-align: left !important;
}

.mat-toolbar {
  background: white;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  border-radius: 15%;
  padding: 5px !important;
  width: 100px;
  background: white;
  height: 125px;
  width: 125px;
  margin: 5px;
  border: 2px solid #d9d9d9;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
  border: none !important;
}
.mat-tab-body.mat-tab-body-active{
  overflow: hidden;
}